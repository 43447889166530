<template>
  <div v-click-outside="focusOut" class="position-relative">
    <FloatLabel
      :class="`${selectDisabled || this.options.length === 0 ? '' : ''}`"
      :label="label"
      :optional="optional"
      :ask="ask" 
      :tooltip="tooltip"
    >
      <div
        :id="id"
        class="shadow-none form-control select-custom"
        @click="handleFocus"
        ref="customSelect"
        :class="[
          `${error ? 'invalid' : ''} ${
            selectDisabled || this.options.length === 0 ? '' : ''
          } ${this.selectValue !== '' ? '' : 'gray'} ${
            isClear && !isVariant ? 'option-custom' : ''
          }`,
        ]"
      >
        <p class="mb-0 lh-2 text-nowrap overflow-hidden">
          {{
            this.val !== ""
              ? isVariant
                ? this.options.find((option) => option.value === this.val).key
                : this.val
              : this.options.length === 0
              ? "N/A"
              : "Select one"
          }}
        </p>
      </div>
      <b-icon
        v-if="isClear"
        icon="x"
        class="clear-select-icon"
        @click="handleResetOptions"
      ></b-icon>
    </FloatLabel>
    <div
      class="result-panel"
      v-if="this.togglePanel"
      ref="scrollContainer"
      @scroll="handleScroll"
    >
      <ul>
        <li :class="`${this.val === '' ? 'active' : 'disable-text'}`" ref="option">
          Select one
        </li>
        <li
          :class="{ selected: selectValue === option, 'option-items': label === 'Service history' }"
          v-for="(option, index) in paginated"
          :key="index"
          :value="
            isVariant ? option.value : isColour ? option.toUpperCase() : option
          "
          @click="handleChange(option)"
          ref="option"
        >
          {{ isVariant ? mappedDisplay(option.key) : mappedDisplay(option) }}
        </li>
      </ul>
    </div>
    <div class="fs-12 text-right mb-2 mt-n3 pr-2 err-text">
      {{ error ? helperText : "" }}
    </div>
    <img
      v-if="isLoading && isLoadAble"
      class="input-spinner"
      src="../../assets/images/input-spinner.gif"
    />
  </div>
</template>
<script>
import {
  FETCH_INIT,
  CLEAR_INFO_MODEL,
  CLEAR_INFO_YEAR,
  CLEAR_INFO_EXTEND,
  CLEAR_INFO_BODY_TYPES,
  CLEAR_INFO_DRIVE_TYPES,
  CLEAR_INFO_TRANS,
} from "@/store/info/actions";
import { mapState } from "vuex";
export default {
  components: {
    FloatLabel: () => import("./FloatLabel.vue"),
  },
  name: "CustomClearSelect",
  inheritAttrs: false,
  model: {
    prop: "selectValue",
    event: "change",
  },
  data() {
    return {
      isLoading: false,
      isDOMUpdateComplete: false,
      togglePanel: false,
      limit: 20,
      page: 1,
    };
  },
  props: {
    selectValue: {
      type: [Number, String],
      default: () => "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: String,
    name: {
      type: String,
      default: () => "",
    },
    mapper: {
      type: Object,
      default: () => null,
      require: false,
    },
    isLoadAble: {
      type: Boolean,
      default: () => false,
    },
    id: {
      type: String,
      default: () => "",
      require: true,
    },
    error: Boolean,
    helperText: String,
    isFocus: Boolean,
    optional: Boolean,
    selectDisabled: Boolean,
    isClear: Boolean,
    ask: {
      type: Boolean,
      default: () => false
    },
    tooltip: {
      type: String,
      default: () => ""
    },
  },
  computed: {
    val: {
      get: function () {
        return this.selectValue;
      },
      set: function () {},
    },
    ...mapState({
      info: (state) => state.info.curVehicleInfo,
    }),
    totalPage() {
      return Math.ceil(this.options.length / this.limit);
    },
    paginated() {
      return this.page <= this.totalPage
        ? [...this.options].slice(0, this.limit * this.page)
        : [...this.options];
    },
    isVariant() {
      return this.label === "Variant" || this.label === "Service history";
    },
    isColour() {
      return this.label === "Colour";
    },
  },
  methods: {
    handleChange: function (option) {
      this.$emit("change", this.isVariant ? option.value : option);
      this.$emit("setNextInput");
      this.togglePanel = false;
    },
    convertShiftValue: function (e) {
      if (e === "AT") {
        return "Automatic";
      } else {
        return "Manual";
      }
    },
    optionParser: function (e) {
      return e !== "Not sure" ? "MY" + e.toString().substring(2) : e;
    },
    handleResetOptions: function () {
      if (this.label === "Model") {
        this.$store.dispatch(FETCH_INIT, {
          make: this.info.make,
        });
        this.$store.dispatch(CLEAR_INFO_MODEL);
      } else if (this.label === "Year") {
        this.$store.dispatch(FETCH_INIT, {
          make: this.info.make,
          model: this.info.model,
        });
        this.$store.dispatch(CLEAR_INFO_YEAR);
      } else if (this.label === "Variant") {
        this.$store.dispatch(FETCH_INIT, {
          make: this.info.make,
          model: this.info.model,
          year: this.info.year,
        });
        this.$store.dispatch(CLEAR_INFO_EXTEND);
      } else if (this.label === "Body type") {
        this.$store.dispatch(FETCH_INIT, {
          make: this.info.make,
          model: this.info.model,
          year: this.info.year,
          badge: this.info.badge,
          edition: this.info.edition,
        });
        this.$store.dispatch(CLEAR_INFO_BODY_TYPES);
      } else if (this.label === "Drive type") {
        this.$store.dispatch(FETCH_INIT, {
          make: this.info.make,
          model: this.info.model,
          year: this.info.year,
          badge: this.info.badge,
          edition: this.info.edition,
          bodyType: this.info.bodyType,
          fuelType: this.info.fuelType,
        });
        this.$store.dispatch(CLEAR_INFO_DRIVE_TYPES);
      } else if (this.label === "Transmission") {
        this.$store.dispatch(FETCH_INIT, {
          make: this.info.make,
          model: this.info.model,
          year: this.info.year,
          badge: this.info.badge,
          edition: this.info.edition,
          bodyType: this.info.bodyType,
          fuelType: this.info.fuelType,
          drive: this.info.drive,
        });
        this.$store.dispatch(CLEAR_INFO_TRANS);
      }
    },
    mappedDisplay: function (e) {
      if (this.mapper !== null) {
        return this.mapper[this.label][e] || e;
      }
      return e;
    },
    handleFocus: function () {
      if (!this.isDOMUpdateComplete) {
        this.isLoading = true;
      } else {
        this.handleTogglePanel();
      }
    },
    handleTogglePanel() {
      this.togglePanel = !this.togglePanel;
    },
    handleScroll: function () {
      const scrollScreen = this.$refs.scrollContainer;
      const bottomOfScreen =
        scrollScreen.scrollTop >= scrollScreen.offsetHeight * this.page;
      if (bottomOfScreen && this.page < this.totalPage) {
        this.page++;
      }
    },
    focusOut() {
      this.togglePanel = false;
    },
  },
  async updated() {
    await this.$nextTick();
    if (this.options.length > 1 || this.val !== "") {
      this.isDOMUpdateComplete = true;
      if (this.isLoading) {
        this.isLoading = false;
        this.togglePanel = true;
      }
    }
  },
};
</script>
<style lang="scss">
select.form-control.invalid,
input.form-control.invalid {
  border-color: red !important ;
}
select.form-control.disabled {
  border: none;
}
.float-label > span {
  z-index: 0;
  background-color: transparent;
  &::after {
    background: none;
  }
}
.err-text {
  color: red;
  font-style: italic;
  min-height: 16px;
}
.border-lable-flt {
  margin-bottom: 0;
}
.non-foucus {
  border-color: #ced4da !important;
}
.next-select-input {
  color: #383c3f;
  background-color: #fff;
  border-color: #00bb65 !important;
  outline: 0;
}
.select-custom {
  position: relative;

  &.gray {
    color: #989898 !important;
  }

  option {
    color: #383c3f !important;

    &:first-child {
      color: #989898 !important;
    }
  }
}
.clear-select-icon {
  position: absolute;

  right: 33px;
  bottom: 27%;
  font-size: 25px;
  cursor: pointer;
}

.option-custom {
  padding-right: 38%;
}

.input-spinner {
  position: absolute;
  top: 50%;
  right: 35px;
  transform: translateY(-85%);
  width: 24px;
}

.disable-text {
  pointer-events: none;
  color: #929090;
  user-select: none;
}

.option-items {
  height: max-content !important;
}

@media screen and(min-width: 768px) {
  .input-spinner {
    right: 45px !important;
  }
}
</style>